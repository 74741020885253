// Google:
export const ANALYTICS_MESS_ID = 'G-DXHX3Y13KZ'
export const ADSENSE_TRACKING_ID = 'ca-pub-2335013571820161'
export const RECAPTCHA_KEY = '6LdVyyMcAAAAACXul89K-Epl2dQu3XOvOo1bTIg3'

// Emailjs:
export const SERVICE_ID = 'service_608vcxh'
export const TEMPLATE_ID = 'template_nledi86'
export const USER_ID = 'user_8TjzzLK0ZdfUQd7h7cieX'

